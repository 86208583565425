<template>
  <div :class="['teamMember', {'gray': team_mate.is_absent}]">
    <div class="memberDetails">
      <div class="picture">{{team_mate.user.user_name[0]}}</div>
      <div class="playerInfo">
        <h4>
          {{ team_mate.user.user_name }}
          <span class="captain material-icons" v-if="is_captain && isAdminView">stars</span>
        </h4>
        <p>
          Ingame: {{ team_mate.player_name }}
        </p>
      </div>
    </div>
    <div class="actions" v-if="(!is_captain && currentUserIsCaptain) || isAdminView">
      <IconButton v-if="promote && !isAdminView" :button-function="() => promote(team_mate.user_id)" button-type="fluid">
        <span class="material-icons icon">
          star
        </span>
      </IconButton>

      <IconButton  v-if="kickPlayer && !isAdminView" :button-function="() => kickPlayer(team_mate.user_id)" button-type="fluid">
        <span class="material-icons icon">
          person_remove
        </span>
      </IconButton>

      <IconButton v-if="setAbsent && isAdminView && [3,4].includes(registrationPhase)" :button-function="()=>setAbsent(team_mate.user_id)" button-type="fluid">
        <span class="material-icons-outlined">
          {{team_mate.is_absent?"person_off":"person"}}
        </span>
      </IconButton>
    </div>

    <span class="captain material-icons" v-else-if="is_captain">
          stars
    </span>

  </div>
</template>

<script>
import store from "../../store";
import IconButton from "../Buttons/IconButton";

export default {
  name: "TeamMember",

  components: {IconButton},

  props: {
    team_mate: Object,
    captain_id: Number,
    promote: Function,
    kickPlayer: Function,
    isAdminView: {
      type: Boolean,
      default: false,
    },
    registrationPhase: Number,
    setAbsent: Function,
  },

  computed: {
    currentUserIsCaptain() {
      return store.state.account.user.userdata.user_id === this.captain_id;
    },
    is_captain() {
      return this.team_mate.user_id === this.captain_id;
    },
  }
}
</script>

<style lang="scss" scoped>

@import "../../styles/themes.scss";

.gray {
  filter: grayscale(1);
}

.captain {
  color: #FEBE5D;
}
.picture {
  @include theme {
    background-color: theme-get('primary-background');
    color: theme-get('primary-color');
  }
  height: 88px;
  width: 88px;
  min-width: 88px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  font-weight: 600;
}
.playerInfo{
  display: flex;
  width: 100%;
  flex-direction: column;
  text-align: left;
  min-width: 0;
  h4{
    font-weight: 600;

    margin:0;

    text-align: left;
    font-size: 2em;
    overflow: hidden;
    text-overflow: ellipsis;
    @include theme {
      color: theme-get('header-2');
    }
  }
  p{
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 1em;
    margin:0;
    @include theme {
      color: theme-get('text-2');
    }
  }
}


.actions {
  @include theme {
    color: theme-get('text-2');
  }
  cursor:pointer;
  display: flex;
  gap:16px;
}


.teamMember {
  width:100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .memberDetails{
    h4{
      width: 100%;
    }
    min-width: 0;
    display: flex;
    gap:24px;
    align-items: center;
  }
  @include theme {
    background-color: theme-get('background-color-1');
    box-shadow: theme-get('shadow-2');
  }
  background-color: white;
  border-radius: 16px;
  padding: 32px;
}

@media screen and (max-width: 768px) {
  .playerInfo{
    text-align: center;
    min-width: 0;
    align-items: center;
    h4{
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
    }

  }

  .teamMember{
    min-width: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .memberDetails{

    display: flex;
    flex-direction: column;
    align-items: center;

  }
}

@media screen and (max-width: 480px){
  /*.teamMember{
    display: flex;
    flex-direction: row;

  }*/
  .playerInfo{
    h4{
      text-align: center;
      font-size: 1.5em;
    }
  }

  .picture {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 75px;
    width: 75px;
    height: 75px;
  }
  .actions {
    margin-top: 5px;
  }

}
</style>
